<template>
  <div class="annotation-box">
    <div class="annotation-nbox">
      <div class="contant-box">
        <!-- 标题 时间 -->
        <h4 class="contant-title">
          {{ $t("analysisReport") }}
        </h4>
        <div class="contant-time">{{ houseData.dateTitle }}</div>

        <!-- 地址 -->
        <div class="contant-con">
          <div class="u-flex" style="margin-bottom: 0.25rem">
            <img
              class="contant-icon"
              src="../../assets/images/icon_addr_gray.png"
            />
            <div class="contant-font">{{ houseData.address }}</div>
          </div>

          <!-- 建筑年份 -->
          <div class="u-flex">
            <van-icon
              name="clock"
              size="0.4rem"
              color="#ef7b74"
              class="contant-icon"
            />
            <div class="contant-font">
              {{ $t("constructionYearX", { n: houseData.houseYear }) }}
            </div>
          </div>
        </div>
      </div>

      <div class="analyze-box">
        <!-- 九宫格平面图 -->
        <div class="analyze-ntit">{{ $t("ninePacePlan") }}</div>
        <div class="flat-img" @click="handlePreview">
          <van-loading
            v-if="!houseData.originalImg"
            type="spinner"
            color="#d1b06f"
          />
          <template v-else>
            <img
              :src="houseData.originalImg"
              :style="{
                transform: `rotate(${stepperValue.num}deg) scale(${
                  stepperValue.size / 100
                })`,
                left: `${stepperValue.left}px`,
                top: `${stepperValue.top}px`,
              }"
            />

            <ul
              class="jiugong-box"
              :style="{
                transform: `scale(${jggWidth}, ${jggHeight})`,
              }"
            >
              <li
                v-for="i in lettersNinePalace"
                :key="i"
                class="u-flex u-row-center u-col-center"
                :class="[
                  i === 'D' || i === 'E' || i === 'F' ? 'border-tb-none' : '',
                  i === 'B' || i === 'E' || i === 'H' ? 'border-lr-none' : '',
                ]"
              >
                <span
                  class="jiugong-span u-flex u-row-center u-col-center"
                  :style="{
                    transform: `scale(${1 / jggWidth}, ${1 / jggHeight})`,
                  }"
                >
                  {{ i }}
                </span>
              </li>
            </ul>
          </template>
        </div>

        <!-- 风水九宫图 -->
        <div class="analyze-ntit">{{ $t("fengShuiNinePalaces") }}</div>
        <div class="fengshui-box">
          <van-row class="jiugong-box">
            <!-- 九宫单个元素 -->
            <van-col
              span="8"
              v-for="item in houseData.fengShuiData"
              :key="item.index"
            >
              <!-- 九宫元素内部元素 -->
              <div
                class="u-flex-col u-row-between jiugong-item"
                :style="{ background: item.bgColor }"
              >
                <div class="jiugong-top u-flex u-row-between">
                  <div class="jiugong-top-index">{{ item.index }}</div>
                  <div
                    class="jiugong-top-title"
                    :style="{
                      background:
                        item.title.indexOf('流年财位') != -1
                          ? '#FFAB02'
                          : '#B49E71',
                    }"
                    v-if="item.title"
                  >
                    {{ item.title }}
                  </div>
                </div>
                <div class="jiugong-bot u-flex u-flex-wrap">
                  <div
                    v-for="(i, index) in item.list"
                    :key="index"
                    :style="{
                      color: i.color,
                      width: '50%',
                    }"
                    class="u-flex u-row-center"
                  >
                    <div class="jiugong-bot-item u-flex u-flex-1 u-row-center">
                      <img
                        class="jiugong-icon"
                        :src="i.img"
                        :style="{ borderColor: i.color }"
                      />
                      <img
                        class="right-status"
                        :src="
                          require(`../../assets/images/icon_${i.status}.png`)
                        "
                      />
                    </div>
                    <div class="u-flex u-flex-1 u-row-center">
                      <span>{{ JSON.parse(i.title).amount }}</span>
                      <span>{{ JSON.parse(i.title).unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </van-col>
          </van-row>

          <!-- 对应风水物颜色文案解释 -->
          <div class="u-flex tips">
            <div class="u-flex u-row-center u-flex-1 tips-item">
              <img :src="require('../../assets/images/icon_1.png')" />
              <span>{{ $t("fengShuiTips1") }}</span>
            </div>
            <div class="u-flex u-row-center u-flex-1 tips-item">
              <img :src="require('../../assets/images/icon_2.png')" />
              <span>{{ $t("fengShuiTips2") }}</span>
            </div>
            <div class="u-flex u-row-center u-flex-1 tips-item">
              <img :src="require('../../assets/images/icon_3.png')" />
              <span>{{ $t("fengShuiTips3") }}</span>
            </div>
          </div>
        </div>

        <!-- 注视摆放文字介绍 -->
        <div class="tips-content">
          <div class="tips-content-title u-flex">
            <van-icon color="#B49E71" size="14" name="question-o" />
            <!-- 图标注解 -->
            <span>{{ $t("iconAnnotation") }}</span>
          </div>
          <ul class="tips-content-middle" style="padding-bottom: 0">
            <li>
              <div class="u-flex">
                <van-image
                  width="0.28rem"
                  height="0.28rem"
                  :src="require('../../assets/images/icon_ls.png')"
                />
                <strong>{{ $t("iconAnnotationTitles[0]") }}</strong>
              </div>
              <span>{{ $t("iconAnnotationContents[0]") }}</span>
            </li>
          </ul>
          <transition :name="isShowTips ? 'van-slide-up' : 'van-slide-down'">
            <ul v-show="isShowTips" class="tips-content-middle">
              <li v-for="(item, index) in tipsContentList" :key="index">
                <div class="u-flex">
                  <van-image
                    width="0.28rem"
                    height="0.28rem"
                    :src="require(`../../assets/images/icon_${item.icon}.png`)"
                  />
                  <strong>{{ item.title }}</strong>
                </div>
                <span>{{ item.tips }}</span>
              </li>
            </ul>
          </transition>
          <div
            class="tips-content-opear u-flex u-row-center"
            @click="swtichIsShowTips"
          >
            <!-- 展开收起 -->
            <span>{{ isShowTips ? $t("putAway") : $t("unfold") }}</span>
            <van-icon
              color="#B49E71"
              size="11"
              :name="isShowTips ? 'arrow-up' : 'arrow-down'"
            />
          </div>
        </div>

        <!-- 九宫位文字详情 -->
        <ul v-if="houseData.annoList.length" class="nine-houses-details">
          <!-- 注意事项 -->
          <li class="precautions">{{ $t("precautions") }}</li>
          <li v-for="(item, index) in houseData.annoList" :key="index">
            <span class="letter">
              {{ `${item.annonum}’` }}
              <span>{{ item.annoDesc }}</span>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <!-- 日期下拉选 -->
    <van-action-sheet
      v-model="dateListPropshow"
      :actions="dateList"
      close-on-click-action
      :round="false"
      @select="handleChange"
    />

    <!-- 图片预览 -->
    <van-popup
      v-model="previewShow"
      position="right"
      :style="{
        height: '100%',
        width: '100%',
        background: '#fbf7f3',
        overflow: 'hidden',
      }"
      :overlay="false"
      @close="handleClosePreview"
    >
      <div class="u-flex-col" style="height: 100%">
        <div class="preview-top u-flex-col u-col-center u-row-right">
          <span>{{ $t("preview") }}</span>
        </div>
        <div class="flat-img">
          <img
            :src="houseData.originalImg"
            :style="{
              transform: `rotate(${stepperValue.num}deg) scale(${
                stepperValue.size / 100
              })`,
              left: `${stepperValue.left}px`,
              top: `${stepperValue.top}px`,
            }"
          />

          <ul
            class="jiugong-box"
            :style="{
              transform: `scale(${jggWidth}, ${jggHeight})`,
            }"
          >
            <li
              v-for="i in lettersNinePalace"
              :key="i"
              class="u-flex u-row-center u-col-center"
              :class="[
                i === 'D' || i === 'E' || i === 'F' ? 'border-tb-none' : '',
                i === 'B' || i === 'E' || i === 'H' ? 'border-lr-none' : '',
              ]"
            >
              <span
                class="jiugong-span u-flex u-row-center u-col-center"
                :style="{
                  transform: `scale(${1 / jggWidth}, ${1 / jggHeight})`,
                }"
              >
                {{ i }}
              </span>
            </li>
          </ul>
        </div>
        <div class="preview-bottom u-flex-col u-row-between">
          <!-- 返回 -->
          <template>
            <div />
            <Buttons
              class="annotation-btn annotation-btn-left"
              @click.native="previewShow = false"
            >
              {{ $t("returnPre") }}
            </Buttons>
          </template>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getResolveDetail, queryParseDate } from "@/api/houseDetail";
import Buttons from "@/components/Buttons";

export default {
  name: "houseDetail",
  components: { Buttons },
  data() {
    return {
      token: this.$route.query.token,

      // 图片是否旋转
      stepperVal: 0,

      // 日期列表弹窗状态
      dateListPropshow: false,

      // 日期列表弹窗集合
      dateList: [],

      // 访问参数
      params: {
        houseId: this.$route.query.houseId,
        resolveId: this.$route.query.resolveId,
      },
      // 页面展示数据
      houseData: {
        //顶部时间标题
        dateTitle: "",

        // 地址
        address: "",

        // 角度
        degree: "",

        // 朝向
        direction: "",

        // 平面图
        directionImg: "",

        // 拍摄图
        photoImg: "",

        // 建筑年份
        houseYear: "",

        // 是否是香港地区0-否 1-是
        isHongKong: 0,

        // 备注
        remark: "",

        // 最后更新时间
        lastUpdateTime: "",

        // 九宫格背景原图
        originalImg: "",

        // 风水九宫数据
        fengShuiData: [],

        // 九宫格批注数据
        annoList: [],

        // 用户旋转的度数
        rotatingDegree: 0,

        // 命理师旋转的角度
        positionDegree: 0,

        // 网站标题
        topTitle: "",
      },
      // 字母九宫数据
      lettersNinePalace: ["A", "B", "C", "D", "E", "F", "G", "H", "I"],

      stepperValue: {
        // 平面图旋转角度的默认值（控制旋转的数值）
        num: 0,
        // 平面图的默认大小(控制大小的数值)
        size: 1,
        // 平面图的定位left值(控制左右的值)
        left: 0,
        // 平面图的定位top值(控制上下的值)
        top: 0,
      },
      jiugongBoxStyle: {
        // 字母九宫格的样式最大只能跟容器的宽高一样，不可超出
        scaleX: 0,
        scaleY: 0,
      },

      // 摆放物品的文字注释介绍
      tipsContentList: [
        {
          icon: "xy",
          title: this.$t("iconAnnotationTitles[1]"),
          tips: this.$t("iconAnnotationContents[1]"),
        },
        {
          icon: "hz",
          title: this.$t("iconAnnotationTitles[2]"),
          tips: this.$t("iconAnnotationContents[2]"),
        },
        {
          icon: "tt",
          title: this.$t("iconAnnotationTitles[3]"),
          tips: this.$t("iconAnnotationContents[3]"),
        },
        {
          icon: "jz",
          title: this.$t("iconAnnotationTitles[4]"),
          tips: this.$t("iconAnnotationContents[4]"),
        },
        {
          icon: "ys",
          title: this.$t("iconAnnotationTitles[5]"),
          tips: this.$t("iconAnnotationContents[5]"),
        },
        {
          icon: "sz",
          title: this.$t("iconAnnotationTitles[6]"),
          tips: this.$t("iconAnnotationContents[6]"),
        },
        {
          icon: "dt",
          title: this.$t("iconAnnotationTitles[7]"),
          tips: this.$t("iconAnnotationContents[7]"),
        },
      ],

      // 是否展开风水九宫文字注释
      isShowTips: false,

      // 图片预览
      previewShow: false,
    };
  },
  watch: {
    $route() {
      window.scrollTo(0, 0);
      this.params.houseId = this.$route.query.houseId;
      this.params.resolveId = this.$route.query.resolveId;
      this.getHouseDetail();
      this.queryParseDate();
    },
  },
  created() {
    // 调用客户端筛选日期唤醒H5的日期下拉选
    window["changeDate"] = () => {
      this.changeDate();
    };
  },
  mounted() {
    if (this.$route.query.token) {
      this.setToken(this.$route.query.token);
    }
    this.getHouseDetail();
    this.queryParseDate();
  },
  computed: {
    // 九宫格的宽度
    jggWidth: function () {
      return this.jiugongBoxStyle.scaleX / 100;
    },
    // 九宫格的高度
    jggHeight: function () {
      return this.jiugongBoxStyle.scaleY / 100;
    },
    // 家宅图在放大缩小后可以向右移动的最大值的范围
    computedPosLeftMax: function () {
      return Math.round(
        ((this.flatImgHeight * this.stepperValue.size) / 100 +
          this.flatImgHeight) /
          2
      );
    },
    // 家宅图在放大缩小后可以向左移动的最小值的范围
    computedPosLeftMin: function () {
      return Math.round(
        ((this.flatImgHeight * this.stepperValue.size) / 100 +
          this.flatImgHeight) /
          -2
      );
    },
    // 家宅图在放大缩小后可以向上移动的最大值的范围
    computedPosTopMax: function () {
      return Math.round(
        ((this.flatImgHeight * this.stepperValue.size) / 100 +
          this.flatImgHeight) /
          2
      );
    },
    // 家宅图在放大缩小后可以向下移动的最小值的范围
    computedPosTopMin: function () {
      return Math.round(
        ((this.flatImgHeight * this.stepperValue.size) / 100 +
          this.flatImgHeight) /
          -2
      );
    },
  },
  methods: {
    getHouseDetail() {
      getResolveDetail(this.params).then((res) => {
        if (res.code === 200) {
          this.houseData = res.data;
          document.title = this.houseData.topTitle;

          if (this.houseData.rotatingStyle) {
            let stepperValueObj = JSON.parse(
              this.houseData.rotatingStyle
            ).stepperValue;
            let jiugongBoxStyleObj = JSON.parse(
              this.houseData.rotatingStyle
            ).jiugongBoxStyle;

            this.stepperValue = {
              left: stepperValueObj.left,
              num: stepperValueObj.num,
              size: stepperValueObj.size,
              top: stepperValueObj.top,
            };
            this.jiugongBoxStyle = {
              scaleX: jiugongBoxStyleObj.scaleX,
              scaleY: jiugongBoxStyleObj.scaleY,
            };
          }
        }
      });
    },

    queryParseDate() {
      queryParseDate(this.params.houseId).then((res) => {
        // 日期下拉集合
        if (res.code === 200) {
          this.dateList = res.data.map((item) => {
            return {
              name: item.title,
              houseId: item.houseId,
              resolveId: item.resolveId,
              color:
                Number(item.resolveId) === Number(this.params.resolveId)
                  ? "#968054"
                  : "",
            };
          });
        }
      });
    },

    // 切换日期下拉选
    changeDate() {
      if (this.dateList.length) {
        this.dateListPropshow = true;
      }
    },

    // 选中日期更新数据
    handleChange(action) {
      if (!action.color) {
        let pathUrl = this.$route;
        this.params.resolveId = action.resolveId ? action.resolveId : "";
        const { houseId, resolveId } = this.params;

        this.$router.replace({
          path: `${pathUrl.path}?houseId=${houseId}&resolveId=${resolveId}`,
        });
      }
    },

    // 监听九宫风水摆放物品的文案展开折叠
    swtichIsShowTips() {
      this.isShowTips = !this.isShowTips;
    },

    // 预览事件
    handlePreview() {
      // 隐藏安卓顶部的区域
      this.handleHideBarView();
      this.previewShow = true;
    },

    // 监听图片预览返回
    handleClosePreview() {
      // 显示安卓顶部的区域
      this.handleShowBarView();
    },
  },
};
</script>

<style lang="scss" scoped>
.annotation-box {
  background: #e6dcc9;
  min-height: 100%;

  .annotation-nbox {
    border-radius: 0.6rem 0.6rem 0 0;
    background: #fbf7f3;
  }
  // 详情
  .contant-box {
    padding: 0.7rem 0.4rem 0;

    .contant-title {
      font-size: 0.4rem;
      font-weight: 600;
      color: #515151;
      line-height: 25px;
    }

    .contant-time {
      font-size: 0.28rem;
      color: #755a24;
      margin: 0.02rem 0 0.46rem;
    }

    .contant-con {
      background: #eee7dc;
      border-radius: 0.08rem;
      padding: 0.2rem;
      margin: 0.36rem 0 0.48rem;
    }

    .contant-item {
    }

    .contant-icon {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.12rem;
    }

    .contant-font {
      font-size: 0.28rem;
      color: #515151;
      line-height: 0.4rem;
    }
  }

  // 分析报告
  .analyze-box {
    padding: 0 0.4rem 1rem;

    .analyze-ntit {
      font-size: 0.28rem;
      color: #515151;
      line-height: 0.4rem;
      margin-bottom: 0.24rem;
    }

    .nine-houses-details {
      background: #eee7dc;
      border-radius: 0.16rem;
      padding: 0.24rem 0.2rem;
      box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.15);

      .precautions {
        font-size: 0.32rem;
        font-weight: 600;
        color: #755a24;
        margin-bottom: 0.24rem;
      }

      > li {
        font-size: 0.28rem;
        color: #525252;
        margin-bottom: 0.35rem;
        word-wrap: break-word;
        word-break: break-all;

        .letter {
          font-size: 0.35rem;
          font-weight: 500;

          > span {
            margin-left: 0.3rem;
            color: #755a24;
          }
        }
      }
    }

    .tips-content {
      margin-top: 1.34rem;
      color: #b49e71;
      background: #ffffff;
      border-radius: 0.08rem;
      padding: 0.24rem;
      margin-bottom: 0.48rem;
      box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.15);

      .tips-content-title {
        font-size: 0.28rem;

        > span {
          margin-left: 0.06rem;
        }
      }

      .tips-content-middle {
        color: #515151;
        font-size: 0.26rem;
        padding: 0.22rem 0 0.56rem;
        text-align: justify;

        > li {
          margin-bottom: 0.4rem;
        }

        > li:last-child {
          margin-bottom: 0;
        }

        strong {
          margin-left: 0.08rem;
        }

        div.u-flex {
          margin-bottom: 0.14rem;
        }
      }

      .tips-content-opear {
        padding-top: 0.24rem;
        border-top: 0.02rem solid rgba(151, 151, 151, 0.22);
        font-size: 0.24rem;
      }
    }
  }
}

// 平面图样式
.flat-img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.15);
  border-radius: 0.08rem;
  margin-bottom: 0.4rem;

  > img {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all ease 0.5s;
    object-fit: scale-down;
  }

  .jiugong-box {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    top: 0.02rem;
    left: 0.02rem;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all ease 0.4s;

    > li {
      width: 33.3333%;
      height: 0;
      padding-top: 33.3333%;
      border: 0.02rem solid #da2c2c;
      box-sizing: border-box;
      color: #da2c2c;
      font-size: 0.36rem;
      font-weight: 800;
      position: relative;

      .jiugong-span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    .border-tb-none {
      border-top: none;
      border-bottom: none;
    }
    .border-lr-none {
      border-left: none;
      border-right: none;
    }
  }
}

// 风水九宫样式
.fengshui-box {
  margin-bottom: 1.2rem;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;

  .jiugong-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    padding: 0.1rem;
    background: #ffffff;
    border-radius: 0.08rem;
    box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.15);

    /deep/ {
      .van-col {
        position: relative;
        width: 33.33333333%;
        height: 0;
        padding-top: 32.3%;
        border-right: 0.1rem solid #ffffff;
        border-bottom: 0.1rem solid #ffffff;

        &:nth-child(3n) {
          border-right: 0;
        }

        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9) {
          border-bottom: 0;
        }

        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          color: #000;
        }

        .jiugong-item {
          padding: 0.16rem 0.16rem 0.05rem;
          border-radius: 0.08rem;

          .jiugong-top {
            font-weight: 500;
            color: #fff;
            line-height: 0.42rem;

            .jiugong-top-index {
              font-size: 0.32rem;
              font-weight: 500;
              color: #b49e71;
            }
            .jiugong-top-title {
              border-radius: 0.2rem;
              padding: 0.01rem 0.12rem;
              font-size: 0.22rem;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .jiugong-bot {
            font-size: 0.22rem;

            > div {
              margin-bottom: 0.08rem;
            }

            .jiugong-bot-item {
              position: relative;

              .jiugong-icon {
                width: 0.4rem;
                height: 0.4rem;
                border: 0.02rem solid;
                border-radius: 50%;
              }

              .right-status {
                position: absolute;
                width: 0.2rem;
                height: 0.2rem;
                right: 0;
                top: 0;
                z-index: 1;
                background: #ffffff;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }

  .tips {
    font-size: 0.28rem;
    font-weight: 400;
    margin: 0.4rem 0 0.74rem;

    .tips-item {
      background: #f3ede5;
      border-radius: 0.3rem;
      padding: 0.08rem 0;

      img {
        width: 0.32rem;
        height: 0.32rem;
        margin-right: 0.24rem;
      }
    }

    .tips-item:first-child {
      color: #cd542e;
    }

    .tips-item:nth-child(2) {
      margin: 0 0.1rem;
      color: #cb9406;
    }

    .tips-item:last-child {
      color: #bbb8b0;
    }
  }
}

.van-action-sheet__item {
  background-color: #fbf7f3;
  border-bottom: 0.02rem solid #eee7dc;
}

.van-action-sheet__item:last-child {
  border-bottom: none !important;
}

.preview-top {
  height: 2rem;
  padding-bottom: 0.6rem;
  font-size: 0.28rem;
  color: #959392;
  line-height: 0.4rem;

  .preview-top-street {
    font-size: 0.44rem;
    font-weight: 600;
    color: #ab8439;
    line-height: 0.6rem;
  }
}

.preview-bottom {
  height: 5rem;
  padding: 0.4rem 0.48rem 0.8rem;
  font-size: 0.32rem;
  font-weight: 500;
  color: #515151;

  .preview-bottom-btns {
    display: inline-block;
    padding-top: 0.22rem;
    padding-bottom: 0.22rem;
    background: #f4efe6;
    border-radius: 0.42rem;
    font-size: 0.28rem;
    font-weight: 500;
    color: #c6b799;

    .active {
      background: #ab8439;
      padding: 0.22rem 0.46rem;
      border-radius: 0.42rem;
      color: #ffffff;
    }
  }
}
</style>
