import request, { baseUrl } from "@/utils/request";

/**
 * 分页查询解析详情
 * @param {*} data
 * @returns
 */
export function getResolveDetail(params) {
  return request({
    url: `${baseUrl.api}/house/detail`,
    method: "GET",
    headers: {
      needToken: true,
    },
    params,
  });
}

/*
 *@描述: 获取解析日期下拉集合数据
 *@参数: houseId: 家宅id
 *@作者: 金涛
 *@日期: 2021-11-24 18:10:03
 */
export function queryParseDate(houseId) {
  return request({
    url: `${baseUrl.api}/house/resolve/date/list/${houseId}`,
    method: "POST",
    headers: {
      needToken: true,
    },
  });
}
